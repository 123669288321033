import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import db from "../../constants/database";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";

export default class Testimonials extends Component {
    render() {
        return (
            <section className=' bg-[black] w-[100%] h-[100%] mb:h-[100%] px-3 md:px-14 font-abel text-center md:flex md:justify-center md:items-center'>
                <div className='testimonial-content pb-5 pt-16'>
                    <p className='text-[28px] md:text-[40px] md:text-center text-left font-[400] md:mt-10 text-[#ffffff] mb-4'>Clients' Testimonials</p>
                    {/* <div className="border-l-2 border-[white] md:block hidden absolute ml-[100px] z-10"></div> */}
                    <Carousel
                        showArrows={true}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                        interval={6100}>
                        {db.testimonies?.map((item) =>
                            <div key={item.id}>
                                <p className='text-[16px] md:text-[16px] md:mb-4 max-w-[552px] m-auto text-[#ffffff] text-left'>{item.content}</p>
                                <div className="testimony-name-img flex justify-between align-middle w-full h-full max-w-[552px] m-auto">
                                    <Link to={item.linkedin}>
                                        <p className='mb-5 flex items-center text-[16px] md:text-[16px] mt-4 md:mb-10 text-[#B3B3B3] text-left max-w-[450px]'><span className="underline mr-2">{item.name}</span> {item.company}{item.linkedin && <AiFillLinkedin color="white" className="mx-[2px]" />}</p>
                                    </Link>
                                    <div>
                                        {/* <img src={item.path} alt="testimonial" /> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Carousel>
                </div>
            </section>
        );
    }
}