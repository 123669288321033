import React from "react";

// stylesheet
import "./assets/css/styles.css";

// environment
import env from "./config/env";

// navigation
import Router from "./router";

// store
import { Provider } from "react-redux";
import { store } from "./store";

// animation
import AOS from "aos";
import "aos/dist/aos.css";
import { Toaster } from "sonner";

// disable
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (env.enviroment === "production") {
  disableReactDevTools();
}

function App() {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Provider store={store}>
      <Toaster position="top-right" />
      <Router />
    </Provider>
  );
}

export default App;
