// import { useEffect } from "react";
import { Link } from "react-router-dom";

// theme
import Landing from "../../../themes";
import Testimonials from "../../../components/testimonials";
import React from "react";

const PartnerTestimonials: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   window.scroll(0, 600);
  // }, []);

  return (
    <Landing>
      <section className="w-[100%] my-5 px-3 md:px-14 font-abel text-center">
        <div className="md:pr-[65px] flex justify-end">
          <Link
            to="https://25thblue.com"
            className="text-[#0B60D6] md:text-[18px] text-[16px] border-[1px] border-[black] hover:bg-[#CDD8FE] hover:text-[#243677] py-2 px-4 rounded ml-3 md:ml-5 mt-2"
          >
            25th Blue
          </Link>
        </div>
      </section>
      <article
        id="testimonials"
        className="talent-hunters md:h-[511px] bg-[#000000] font-abel text-[white] "
      >
        <div className="talent-hunters-inner flex w-full pt-[50px] md:pb-[100px] md:mt-[50px] justify-between items-center">
          <Testimonials />
        </div>
      </article>
      <section className="w-[100%] my-10 md:my-10 px-3 md:px-14 font-abel text-center">
        <div className="md:w-10/12 mx-auto">
          <h1 className="my-5 text-[30px] md:text-[60px] md:mt-8">
            When you require a partner, think 25th
          </h1>
          <p className="my-5 text-[20px] md:mb-10">
            Our team of consultants is devoted to championing your interests and
            ensuring that your expectations as a client are set accurately. We
            strive to provide you with the best talents available in the market
            and are committed to understanding your business to guide you toward
            the most suitable next steps. With us by your side, you can rest
            assured that we will connect you with the right talent and let you
            focus on your core business.
          </p>

          <div className="text-center my-10 pt-3.5 h-[100px]">
            <Link
              to="/categories"
              className="bg-[#243677] px-16 py-4 rounded text-[#ffffff] text-[20px] my-10"
            >
              <span>Make a request</span>
            </Link>
          </div>
        </div>
      </section>
    </Landing>
  );
};

export default PartnerTestimonials;
