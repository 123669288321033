import React from "react";
import Landing from "../../../themes";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Button from "../../../components/ui/links/Button";

const VipPackage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <Landing>
      <div className="font-abel">
        <div className="flex md:ml-16 ml-4 font-abel my-6">
          <div className="flex justify-between items-center cursor-pointer text-[#243677]">
            <Link to="/">
              <BsArrowLeft size={25} />
            </Link>
            <Link to="/">
              <span className="ml-2 px-1 text-[16px] underline">Home</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="/services/packages">
              <span className="px-1 text-[16px] underline">Packages</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="#">
              <span className="px-1 text-[16px] underline">VIP Package</span>
            </Link>
          </div>
        </div>

        <section className="relative">
          <div className="py-6 md:py-6 font-abel md:px-16 bg-corporatebg bg-cover h-full w-full md:h-[660px] absolute z-0"></div>
          <div className="max-w-[1350px] m-auto w-full px-6 pt-10 pb-32 relative z-10">
            <div className="grid md:grid-cols-4">
              <div className="md:col-span-3 order_2 bg-[#F2F2F2] h-full">
                <div className="bg-[#203171] text-[#ffffff] px-4 md:px-8 py-4 md:py-5">
                  <div className="max-w-[748px] mx-auto">
                    <h1 className="md:text-5xl text-2xl pb-2 md:leading-10">
                      VIP Package
                      <br />
                      <span className="md:text-3xl text-lg">
                        (C-Suite/Board Level)
                      </span>
                    </h1>
                    <p className="text-[15px] max-w-[758px] pt-3">
                      The VIP Package is tailored for C-suite and Board-level
                      roles, offering an all-inclusive, high-touch recruitment
                      process The fee is 25% of the candidate's salary, with a
                      6-month guarantee. All additional services listed below
                      are included in the package at no extra cost.
                    </p>
                  </div>
                </div>
                <div className="text-[#000000] py-4 px-4 md:px-8 font-abel">
                  <div className="max-w-[748px] mx-auto">
                    <h2 className="md:text-[42px] text-3xl pb-3">Features</h2>
                    <ul className="package-bullet md:text-[18px] text-[15px] leading-9 font-abel">
                      <li>
                        Full Market Mapping: Comprehensive global mapping of the
                        executive talent pool, identifying top candidates from
                        various regions and industries.
                      </li>
                      <li>
                        Executive Assessment: Deep-dive assessment of the
                        candidate’s leadership capabilities and alignment with
                        your organizational needs.
                      </li>
                      <li>
                        Due Diligence: Thorough evaluation of the candidate’s
                        professional history, reputation, and cultural fit.
                      </li>
                      <li>
                        Leadership Profiling: Detailed analysis of leadership
                        traits, decision-making processes, and their potential
                        impact on your executive team.
                      </li>
                      <li>
                        Weekly Reporting: Regular updates on the status of your
                        search, progress reports, and candidate evaluations.
                      </li>
                      <li>
                        Priority Handling: VIP treatment ensuring your search is
                        prioritized and handled promptly.
                      </li>
                      <li>
                        Post-Placement Support: Ongoing support for the
                        candidate during their transition into the new role.
                      </li>
                      <li>
                        Dedicated Senior Consultant Team: A team of experienced
                        consultants working exclusively on your executive search
                        to ensure success.
                      </li>
                      <li>
                        CEO Pick and Notes: Personalized involvement from the
                        CEO, ensuring final candidates are closely aligned with
                        the company's vision.
                      </li>
                      <li>
                        Candidate Mapping: In-depth analysis of candidates’
                        strengths, weaknesses, and how they could complement
                        your current leadership team.
                      </li>
                      <li>
                        Employer Brand Alignment: Ensuring that your company’s
                        brand and values are represented accurately throughout
                        the recruitment process.
                      </li>
                      <li>
                        Recruitment Cost-Value Summary: A detailed report
                        analyzing recruitment costs against the potential value
                        brought by the new hire.
                      </li>
                    </ul>
                    <div className="pt-12 md:pb-6 md:-ml-8 flex md:justify-start justify-center">
                      <Button
                        onClick={() => navigate("/order-cooperate-service")}
                        className="text-[#ffffff] border border-[#8F8D8D] btn_border md:text-[18px] text-[12px] hover:text-[black] bg-[#243677] hover:bg-[white] w-[110px] h-[50px] md:w-[253px] md:h-[65px] flex justify-center mb-6"
                      >
                        <span className="text-center">Send a request</span>
                      </Button>
                      <div className="w-[10px] md:w-0"></div>
                      <Button
                        onClick={() => navigate("/services/packages")}
                        className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[18px] text-[12px] hover:text-[black] hover:bg-[white] w-[165px] h-[50px] md:w-[253px] md:h-[65px] flex justify-center mb-6"
                      >
                        <span className="text-center">
                          Explore Additional Services
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="text-[#000000] py-6 px-4 md:px-8 font-abel bg-[#ffffff]">
                  <div className="max-w-[748px] mx-auto mt-4">
                    <h2 className="md:text-[42px] text-3xl pb-3">
                      Inclusive Additional Services:
                    </h2>
                    <ul className="package-bullet md:text-[18px] text-[15px] leading-9 font-abel">
                      <li>
                        Comprehensive Background Check: An in-depth global
                        background check, including criminal, professional, and
                        social media reviews.
                      </li>
                      <li>
                        Offer Letter Draft and Review: Full assistance with
                        drafting the offer letter and reviewing it for clarity,
                        alignment with industry standards, and the candidate’s
                        expectations.
                      </li>
                      <li>
                        Candidate Engagement: Continuous engagement with
                        candidates to keep them informed, excited, and committed
                        throughout the recruitment process.
                      </li>
                      <li>
                        Onboarding Support: Tailored onboarding assistance to
                        ensure the new executive integrates seamlessly into your
                        organization.
                      </li>
                      <li>
                        Salary Negotiation Support: Expert guidance in
                        structuring a competitive and attractive compensation
                        package that aligns with your organization's strategy
                        and market standards.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-span-1 order_1 mb-3">
                <div className="bg-[#203171]">
                  <div>
                    <div className="bg-[#FFFFFF] px-5 py-2 ">
                      <h3 className="text-[25px] md:text-4xl text-[#0B60D6] max-w-[140px] mx-auto">
                        Rebate
                      </h3>
                    </div>
                    <div className="text-[#ffffff] bg-[#203171] px-6 py-2">
                      <div className="max-w-[140px] mx-auto pb-3">
                        <p className="text-[22px]">2 roles: 24%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <Link
                to="/services/terms"
                className="md:text-[18px] text-[15px] text-[#4787E0]"
              >
                *T&C apply
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Landing>
  );
};

export default VipPackage;
