import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { MessageType2 } from "./../types/message";

// Define the initial state using that type
const initialState: MessageType2 = {
  message: null,
  type: null,
  title: "",
  pretest_duration: null,
  user_id: null,
  order_id: null,
  data: null,
};

export const message = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<MessageType2>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.title = action.payload?.title;
      state.pretest_duration = action.payload.pretest_duration;
      state.user_id = action.payload.user_id;
      state.order_id = action.payload.order_id;
      state.data = action.payload.data;
    },
    clearMessage: (state: MessageType2) => {
      state.message = null;
      state.type = null;
      state.title = "";
      state.pretest_duration = "";
      state.user_id = "";
      state.order_id = "";
      state.data = null;
    },
  },
});

export const { setMessage, clearMessage } = message.actions;

export default message.reducer;
