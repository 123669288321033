import React from "react";
import Landing from "../../../themes";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/ui/links/Button";

const RecruitmentPackages: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <Landing>
      <section className="md:py-12 py-8 px-3 md:px-14 font-abel bg-marketplacebg bg-center md:bg-market-place bg-cover">
        <div className="md:pr-[60px] md:right-2 flex justify-end mb-0">
          <Link
            to="https://25thblue.com"
            className="text-[#0B60D6] bg-[white] md:text-[18px] text-[16px] border-[1px] border-[black] hover:bg-[#CDD8FE] hover:text-[#243677] py-2 px-4 rounded ml-3 md:ml-5 mt-2"
          >
            25th Blue
          </Link>
        </div>
        <div className="max-w-[1250px] mx-auto">
          <div className="font-abel mb-1 md:px-12 px-2 pt-2">
            <h3 className="text-3xl md:text-6xl font-[400] max-w-[600px]">
              Recruitment Packages Designed for Success
            </h3>
          </div>
          <div className="md:px-12 md:mt-6 max-w-[749px] px-2">
            <p className="text-[17px] md:text-[20px]  text-[#4E4E4E] font-[400]">
              Hiring the right talent is key to driving any organization
              forward. That's why we've developed flexible recruitment packages
              tailored to meet the needs of businesses at every level—from
              entry-level positions to strategic executive hires.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="font-abel bg-[#F3F6FE] h-full w-full">
        <div className="md:py-14 py-8 px-5 md:px-14 bg-bgSpiral bg-center bg-cover h-full w-full">
          <h2 className="text-[30px] text-center mb-4">
            Why Choose Our Recruitment Services?
          </h2>
          <div className="grid md:grid-cols-4 gap-3">
            <div className="col-span-1 py-4 px-3 border border-[#243677] bg-[#F3F6FE] card_border">
              <p className="text-[17px] font-bold text-[#1E1E1E] mb-3">
                Tailored Solutions:
              </p>
              <p className="text-[17px] text-[#1E1E1E] mb-4">
                Recruitment packages crafted to meet unique hiring needs and
                organizational goals.
              </p>
            </div>
            <div className="col-span-1 py-4 px-3 border border-[#243677] bg-[#F3F6FE] card_border">
              <p className="text-[17px] font-bold text-[#1E1E1E] mb-3">
                Exceptional Service:
              </p>
              <p className="text-[17px] text-[#1E1E1E] mb-4">
                A commitment to delivering unparalleled value with comprehensive
                support at every step.
              </p>
            </div>
            <div className="col-span-1 py-4 px-3 border border-[#243677] bg-[#F3F6FE] card_border">
              <p className="text-[17px] font-bold text-[#1E1E1E] mb-3">
                End-to-End Expertise:
              </p>
              <p className="text-[17px] text-[#1E1E1E] mb-4">
                From candidate sourcing and assessment to onboarding and
                post-placement support, every stage of recruitment is seamless.
              </p>
            </div>
            <div className="col-span-1 py-4 px-3 border border-[#243677] bg-[#F3F6FE] card_border">
              <p className="text-[17px] font-bold text-[#1E1E1E] mb-3">
                Scalable Options:
              </p>
              <p className="text-[17px] text-[#1E1E1E] mb-4">
                Packages that grow with your hiring needs, offering competitive
                pricing and guaranteed results.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="bg-service-hero bg-cover h-[250px] md:h-[454px]">
        <div className="py-4 md:py-8 font-abel mx-2 md:mx-14 my-10 md:my-10 px-6 text-center md:px-16 bg-[rgb(21,21,21,0.6)] h-full w-full flex justify-center items-center">
          <p className="text-[17px] md:text-[28px] px-4 text-[white] md:leading-[40px]">
            These packages offer specialized services and unmatched support,
            ensuring that you don't just fill roles—you build teams that thrive.
            Whether you're hiring for foundational positions or searching for
            high-impact leaders, we provide customized solutions and market
            insights to align with your hiring goals.
          </p>
        </div>
      </section>
      {/* <article className="bg-[#FFFFFF] font-abel text-white px-4 md:py-10 md:px-2 ">
        <p className="text-3xl md:text-6xl font-[400] text-[#000000] max-w-[949px] mx-auto text-center">
          Four-Tier Recruitment Packages
        </p>

        <div className="flex flex-col items-center md:flex-row md:justify-between w-full md:px-14 md:py-8">
          {service.services_details2.map((item) => (
            <ServicesDetail2
              key={item.key}
              item={item}
              onHandler={(res) => navigate(res.link)}
            />
          ))}
        </div>
      </article> */}
      <section className="px-4 md:py-10 py-5 mt-7 w-full max-w-[826px] mx-auto font-abel">
        <p className="text-3xl md:text-6xl font-[400] text-[#000000] max-w-[949px] mx-auto text-center">
          Four-Tier Recruitment Packages
        </p>
        <div className="grid md:grid-cols-2 gap-4 pt-6">
          <div className="col-span-1 bg-[#ffffff] h-full mb-3">
            <div className="bg-[#F2F2F2] px-4 md:px-8 py-4 md:py-5">
              <p className="text-[32px] leading-9 pb-2">
                Standard Plus
                <br />
                (Entry to Mid-Level)
              </p>
              <p className="text-[15px] text-[#4E4E4E] max-w-[535px]">
                Basic assessments, sourcing, and screening, with a focus on
                identifying the right talent quickly and efficiently
              </p>
            </div>
            <div className="text-[#000000] py-4 px-6 package-bullets">
              <p className="text-[16px] leading-7">Fee: 17.5%</p>
              <p className="text-[16px] leading-7">Guarantee: 2 months</p>
              <p className="text-[16px] leading-7">
                Delivery Timeline: 3 - 4 weeks
              </p>
              <h5 className="text-[25px] leading-7 font-bold mt-2 mb-2">
                Key Features:
              </h5>
              <p className="text-[16px] leading-7">Three Interview Stages</p>
              <p className="text-[16px] leading-7">Standard Reporting</p>
              <p className="text-[16px] leading-7">Market Mapping</p>
              <p className="text-[16px] leading-7 pb-14">Shortlist Report</p>
              <div className="pt-[25px] flex justify-center">
                <Button
                  onClick={() => navigate("/services/standard")}
                  className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[17px] text-[15px] hover:text-[black] hover:bg-[white] w-[140px] h-[50px] md:w-[163px] md:h-[50px] flex justify-center mb-6 md:mr-8"
                >
                  <span className="text-center">Learn More</span>
                </Button>
                {/* <Link
                  to="/services/standard"
                  className="text-[#0B60D6] bg-[white] md:text-[18px] text-[16px] h-[65px] border-[1px] border-[black] hover:bg-[#CDD8FE] hover:text-[#243677] md:py-4 py-2 md:px-8 px-4 rounded"
                >
                  Learn More
                </Link> */}
              </div>
              {/* <ul className="mb-4">
                <li>Perfect for: Entry to mid-level roles</li>
                <li>Fee: 17.5% (Total Package)</li>
                <li>Guarantee: 2 months</li>
                <li>Delivery timeline:3-4weeks</li>
                <li>Deposit: 60%</li>
                <li>
                  Vetting: Pre-test (15-20 minutes), in-house tests and
                  interviews (1hour 30 minutes), and potential 30-second videos{" "}
                </li>
                <li>
                  Shortlist Report: At least 2 highly qualified candidates per
                  role
                </li>
              </ul> */}
              {/* <Link to="#">
                <Button className="md:text-[#ffffff] border text-[#FFFFFF] border-[#FFFFFF] hover:text-[#ffffff] hover:bg-[#03124B]">
                  Learn more
                </Button>
              </Link> */}
            </div>
          </div>
          <div className="col-span-1 bg-[#ffffff] h-full mb-3">
            <div className="bg-[#000000] text-[#ffffff] px-4 md:px-8 py-4 md:py-5">
              <p className="text-[32px] leading-9 pb-2">
                Executive Package
                <br />
                (Senior Management)
              </p>
              <p className="text-[15px] max-w-[535px]">
                In-depth market analysis, enhanced screening, and dedicated
                consultants to secure exceptional leadership
              </p>
            </div>
            <div className="text-[#000000] py-4 px-6 package-bullets">
              <p className="text-[16px] leading-7">Fee: 20%</p>
              <p className="text-[16px] leading-7">Guarantee: 3 months</p>
              <p className="text-[16px] leading-7">
                Delivery Timeline: 30 - 45 days
              </p>
              <h5 className="text-[25px] leading-7 font-bold mt-2 mb-2">
                Key Features:
              </h5>
              <p className="text-[16px] leading-7">Indepth Market Analysis</p>
              <p className="text-[16px] leading-7">
                Three Step Assessments Testing
              </p>
              <p className="text-[16px] leading-7">Bi-Weekly Reporting</p>
              <p className="text-[16px] leading-7">Basic Background Checks*</p>
              <p className="text-[16px] leading-7">Dedicated Consultant</p>
              <p className="text-[16px] leading-7 pb-2">Shortlist Report</p>
              <div className="pt-3 flex justify-center">
                <Button
                  onClick={() => navigate("/services/executive")}
                  className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[17px] text-[15px] hover:text-[black] hover:bg-[white] w-[140px] h-[50px] md:w-[163px] md:h-[50px] flex justify-center mb-6 md:mr-8"
                >
                  <span className="text-center">Learn More</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-[#ffffff] mb-3 h-full">
            <div className="bg-[#203171] text-[#ffffff] px-4 md:px-8 py-4 md:py-5">
              <p className="text-[32px] leading-9 pb-2">
                VIP Package
                <br />
                (C-Suite/Board Level)
              </p>
              <p className="text-[15px] max-w-[535px]">
                Basic assessments, sourcing, and screening, with a focus on
                identifying the right talent quickly and efficiently
              </p>
            </div>
            <div className="text-[#000000] py-4 px-3 package-bullets">
              <p className="text-[16px] leading-7">Fee: 25%</p>
              <p className="text-[16px] leading-7">Guarantee: 6 months</p>
              <p className="text-[16px] leading-7">
                Delivery Timeline: 45 - 60 days
              </p>
              <h5 className="text-[25px] leading-7 font-bold mt-2 mb-2">
                Key Features:
              </h5>
              <p className="text-[16px] leading-7">Executive Assessments</p>
              <p className="text-[16px] leading-7">Full Market Mapping</p>
              <p className="text-[16px] leading-7">Recruitment Value Summary</p>
              <p className="text-[16px] leading-7">
                Comprehensive Background Checks
              </p>
              <p className="text-[16px] leading-7">CEO Pick and Notes</p>
              <p className="text-[16px] leading-7">Onboarding Support</p>
              <p className="text-[16px] leading-7">
                Offer Letter Draft and Review
              </p>
              <p className="text-[16px] leading-7 pb-2">Shortlist Report</p>
              <div className="pt-3 flex justify-center">
                <Button
                  onClick={() => navigate("/services/vip")}
                  className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[17px] text-[15px] hover:text-[black] hover:bg-[white] w-[140px] h-[50px] md:w-[163px] md:h-[50px] flex justify-center mb-6 md:mr-8"
                >
                  <span className="text-center">Learn More</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-1 bg-[#03124B] md:h-[673px]">
            <div className="text-[#ffffff] px-4 md:px-8 py-4 md:py-5 md:h-[190px]">
              <p className="text-[32px] leading-9 pb-2 py-3">VVIP Package</p>
            </div>
            <p className="md:text-[50px] text-[40px] text-[#ffffff] md:leading-9 md:pb-8 py-3 px-4 mb-20">
              Coming Soon
            </p>
          </div>
        </div>
        <div className="text-center mt-5">
          <Link
            to="/services/terms"
            className="md:text-[18px] text-[15px] text-[#4787E0]"
          >
            *T&C apply
          </Link>
        </div>
      </section>
      <section className="bg-[#F9FAFF] py-5 md:mt-5 font-abel">
        <h4 className="md:text-[44px] text-[24px] text-[#100F0F] text-center">
          Ready to Build Your Dream Team?
        </h4>
        <div className="bg-[#03124B] text-center text-[#ffffff] py-5 px-4 mt-3 mx-3">
          <p className="md:text-[24px] text-[18px] max-w-[929px] mx-auto">
            This recruitment process is more than just filling positions—it's
            about building a team that drives growth and innovation. Explore
            these packages today to find the perfect solution for your hiring
            needs.
          </p>
          <div className="flex justify-center mt-4">
            <Link to="mailto:info@25thmail.com">
              <Button className="md:text-[#ffffff] border text-[#FFFFFF] border-[#FFFFFF] hover:text-[#ffffff] hover:bg-[#03124B]">
                Get Started Today
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section className="py-5 font-abel text-center">
        <h4 className="md:text-[44px] text-[24px] text-[#100F0F] text-center mb-4">
          Need a Customized Package?
        </h4>
        <p className="md:text-[24px] text-[18px] max-w-[929px] mx-auto px-3">
          If none of these packages fully meet your needs, custom solutions can
          be tailored to your unique hiring goals and organizational challenges.
        </p>
        <div className="flex justify-center mt-4">
          <a
            href="mailto:info@25thmail.com"
            className="text-[#0B60D6] bg-[white] md:text-[18px] text-[16px] border-[1px] border-[black] hover:bg-[#CDD8FE] hover:text-[#243677] md:py-4 py-2 md:px-8 px-4 rounded"
          >
            Contact Us
          </a>
        </div>
      </section>
    </Landing>
  );
};

export default RecruitmentPackages;
