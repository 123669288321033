import React from "react";

// router
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// components
import SuspenceLoader from "../components/suspence";
import Faqs from "../screens/faqs";

// animate
import { AnimatePresence } from "framer-motion";
import RecruitmentPackages from "../screens/services/packages";
import StandardPackage from "../screens/services/standard";
import ExecutivePackage from "../screens/services/executive";
import VipPackage from "../screens/services/vip";
import RecruitmentPackagesTerms from "../screens/services/terms";
import PartnerTestimonials from "../screens/services/partners/testimonials";

// lazy loading
const Teams = React.lazy(() => import("../screens/teams"));
const CoreValues = React.lazy(() => import("../screens/teams/core-values"));
const Origin = React.lazy(() => import("../screens/origin"));
const Home = React.lazy(() => import("../screens/dashboard"));
const Contact = React.lazy(() => import("../screens/contact"));
const Terms = React.lazy(() => import("../screens/agreement/terms"));
const Policy = React.lazy(() => import("../screens/agreement/policy"));
const ViewOffer = React.lazy(() => import("../screens/jobs/show"));
const MarketPlace = React.lazy(() => import("../screens/jobs/market_place"));
const FormSubmittion = React.lazy(() => import("../screens/jobs/applicants"));
const JobSeekers = React.lazy(() => import("../screens/jobs/job_seeker"));
const Partner = React.lazy(() => import("../screens/services/partners"));
const Service = React.lazy(() => import("../screens/services/dashboard"));
const Categories = React.lazy(() => import("../screens/services/categories"));
const ExecutiveEngagement = React.lazy(
  () => import("../screens/services/executive_search")
);
const PermanentContract = React.lazy(
  () => import("../screens/services/permanent_contract")
);
const CorporateService = React.lazy(
  () => import("../screens/services/corporate_service")
);
const ExecutiveOrder = React.lazy(
  () => import("../screens/services/order_executive_role")
);
const CooperateService = React.lazy(
  () => import("../screens/services/order_coperate_service")
);
const OrderPermanentStaff = React.lazy(
  () => import("../screens/services/order_permanent_staff")
);
const PretestQuestions = React.lazy(() => import("../screens/jobs/pretest"));
const AssessmentQuestions = React.lazy(
  () => import("../screens/jobs/assessment")
);
const Feedback = React.lazy(() => import("../screens/jobs/feedback"));

const NotFound = React.lazy(() => import("../screens/notfound"));

const AnimatedRoute = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {/* <Route path="/test" element={<SuspenceLoader />} /> */}

        {/* Redirect */}
        <Route path="/jobs" element={<Navigate to="/marketplace" replace />} />
        <Route
          path="/offers"
          element={<Navigate to="/marketplace" replace />}
        />

        {/* Order Pages */}
        <Route
          path="/order-executive-search"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <ExecutiveOrder />
            </React.Suspense>
          }
        />
        <Route
          path="/order-contract-or-permanent-staff"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <OrderPermanentStaff />
            </React.Suspense>
          }
        />
        <Route
          path="/order-cooperate-service"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <CooperateService />
            </React.Suspense>
          }
        />

        {/* services */}
        {/* <Route path="/make-order" element={<PostJob />} /> */}
        <Route
          path="/categories"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Categories />
            </React.Suspense>
          }
        />
        <Route
          path="/partner"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Partner />
            </React.Suspense>
          }
        />
        <Route
          path="/partner/testimonials"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <PartnerTestimonials />
            </React.Suspense>
          }
        />
        <Route
          path="/services/executive-search"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <ExecutiveEngagement />
            </React.Suspense>
          }
        />
        <Route
          path="/services/permanent-contract"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <PermanentContract />
            </React.Suspense>
          }
        />
        <Route
          path="/services/corporate-service"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <CorporateService />
            </React.Suspense>
          }
        />
        <Route
          path="/services"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Service />
            </React.Suspense>
          }
        />
        <Route
          path="/services/packages"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <RecruitmentPackages />
            </React.Suspense>
          }
        />
        <Route
          path="/services/standard"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <StandardPackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/packages/standard"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <StandardPackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/executive"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <ExecutivePackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/packages/executive"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <ExecutivePackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/vip"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <VipPackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/packages/vip"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <VipPackage />
            </React.Suspense>
          }
        />
        <Route
          path="/services/terms"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <RecruitmentPackagesTerms />
            </React.Suspense>
          }
        />

        {/* jobs view */}
        <Route
          path="job-seeker"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <JobSeekers />
            </React.Suspense>
          }
        />
        <Route
          path="/jobs/:id"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <ViewOffer />
            </React.Suspense>
          }
        />
        <Route
          path="/apply/:id"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <FormSubmittion />
            </React.Suspense>
          }
        />

        {/* landings */}
        <Route
          path="/policy"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Policy />
            </React.Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Terms />
            </React.Suspense>
          }
        />
        <Route
          path="/talk-to-us"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Contact />
            </React.Suspense>
          }
        />
        <Route
          path="/marketplace"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <MarketPlace />
            </React.Suspense>
          }
        />
        <Route
          path="/teams"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Teams />
            </React.Suspense>
          }
        />
        <Route
          path="/teams/core-values"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <CoreValues />
            </React.Suspense>
          }
        />
        <Route
          path="/origin"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Origin />
            </React.Suspense>
          }
        />
        <Route
          path="/faqs"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Faqs />
            </React.Suspense>
          }
        />
        <Route
          path="/pretest/:id"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <PretestQuestions />
            </React.Suspense>
          }
        />
        <Route
          path="/assessment/:id"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <AssessmentQuestions />
            </React.Suspense>
          }
        />
        <Route
          path="/feedback/:id"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Feedback />
            </React.Suspense>
          }
        />
        <Route
          path="/"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Home />
            </React.Suspense>
          }
        />
        {/* <Route path="/" element={<ComingSoon />} /> */}
        <Route
          path="/*"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoute;
