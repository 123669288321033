// features/assessment/assessmentSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AssessmentState {
  assessment_id: string | null;
  feedback_required: boolean;
  feedback_questions: Array<{
    id: string;
    question: string;
    type: string;
  }>;
}

const initialState: AssessmentState = {
  assessment_id: null,
  feedback_required: false,
  feedback_questions: [],
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setAssessmentData: (state, action: PayloadAction<AssessmentState>) => {
      state.assessment_id = action.payload.assessment_id;
      state.feedback_required = action.payload.feedback_required;
      state.feedback_questions = action.payload.feedback_questions;
    },
    clearAssessmentData: (state) => {
      state.assessment_id = null;
      state.feedback_required = false;
      state.feedback_questions = [];
    },
  },
});

export const { setAssessmentData, clearAssessmentData } =
  assessmentSlice.actions;
export default assessmentSlice.reducer;
